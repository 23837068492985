@import '../styles//variables.scss';

.park-page {
    display: flex;
    flex-wrap: wrap;

    > * {
        width: 100%;
    }
}
