@import '../../styles/variables';

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    font-weight: 500;
    font-size: 10px;
    color: $pagination-link-color;

    @include tablet {
        padding: 24px 0;
        font-size: 12px;
    }
}

.pagination-link {
    min-width: 18px;
    padding: 0;
    font-weight: 500;
    font-size: inherit;
    background: none;
    color: $pagination-link-color;

    &:hover,
    &:focus,
    &:active {
        color: $color-secondary;
    }

    &.selected {
        text-decoration: underline;
    }
}

.pagination-list {
    display: flex;
    align-items: center;
    list-style-type: none;
    gap: 3px;
}

.pagination-next {
    letter-spacing: -0.5px;
}
