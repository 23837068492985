@import '../../styles/variables';

.footer {
    display: none;
    flex-shrink: 0;

    @include tablet {
        z-index: 10;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: $color-dark;
        font-size: 0;
        padding: 0 40px 0 48px;
    }
}

.footer-links {
    @include tablet {
        margin: 0 -12px;

        a {
            @include transition;
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            line-height: 14px;
            text-decoration: none;
            color: $color-white;
            margin: 0 12px;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                @include transition;
                color: $color-secondary;
            }
        }
    }
}

.footer-copy {
    @include tablet {
        font-size: 12px;
        line-height: 14px;
        color: $color-white;
        margin: 0 0 0 25px;
    }
}

.social {
    margin: 0 0 0 auto;
    padding: 0;
    list-style-type: none;

    li {
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        margin: 0 8px;

        a {
            @include transition;
            font-size: 15px;
            line-height: 15px;
            text-decoration: none;
            color: $color-white;

            &:hover,
            &:focus,
            &:active {
                @include transition;
                color: $color-secondary;
            }
        }
    }
}
