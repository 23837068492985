@import '../../styles/variables';

.chart {
    display: block;
    width: 100%;
    height: calc(100% - 36px);

    margin-top: 10px;

    @include tablet-large {
        margin-top: 0;
    }

    .card {
        padding: 15px 35px;
        font-size: 12px;
    }

    .chart-wrap {
        display: block;
        box-shadow: $card-shadow;
        border-radius: 10px;
        padding: 40px 6px 40px 20px;
        position: relative;
        font-size: 0;

        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 100%;
        }

        @include tablet {
            height: 100%;
            padding: 40px 6px 40px 40px;
        }
    }

    .chart-content,
    .chart-list {
        display: inline-block;
        vertical-align: middle;
    }

    .chart-content {
        width: 45%;

        @include tablet {
            width: 40%;
        }
    }

    .chart-list {
        width: calc(55% - 30px);
        margin: 0 0 0 30px;
        padding: 0;
        list-style-type: none;

        @include tablet {
            width: calc(60% - 70px);
            margin: 0 0 0 70px;
        }
    }

    .chart-item {
        display: block;
        width: 100%;
        margin: 0 0 14px;

        &:last-child {
            margin: 0;
        }

        @include tablet {
            margin: 0 0 20px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .chart-circle,
    .chart-value {
        display: inline-block;
        vertical-align: top;
    }

    .chart-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 18px 0 0;

        @include tablet {
            width: 20px;
            height: 20px;
            margin: 0 11px 0 0;
        }
    }

    .chart-value {
        width: calc(100% - 28px);
        font-weight: 700;
        font-size: 12px;
        line-height: 1.2;
        color: $color-dark;

        @include tablet {
            width: calc(100% - 31px);
            height: 100%;
            font-size: 18px;
        }
    }
}

.recharts-legend-item {
    font-size: 10px;

    > .recharts-surface {
        vertical-align: top !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
