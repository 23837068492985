@import '../styles/variables';

.profile-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding-top: 16px !important;

    @include tablet-large {
        flex-wrap: nowrap;
        padding-top: 36px !important;
    }

    .profile-card-user-info {
        width: 100%;
        text-align: center;

        @include tablet {
            width: auto;
            margin-bottom: 64px;
        }
    }
}

.profile-card-tabs {
    background-color: $card-background-color;
    box-shadow: $card-shadow;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
}

.profile-card-wrap {
    margin-bottom: 24px;

    @include tablet-large {
        margin-bottom: 0;
    }
}

.profile-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 0 40px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @include tablet {
        padding: 40px 50px;
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
    }

    .pagination {
        margin-top: auto;
    }
}

.profile-card-content-form {
    padding-top: 24px;
    padding-right: 32px;
    padding-left: 32px;

    @include tablet {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

.profile-card-logout {
    display: none;
    margin-top: auto;
    padding: 36px 18px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    background: none;
    color: $color-danger;

    @include tablet {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
}

.home-status-card-container {
    margin-bottom: 30px;

    @include tablet {
        margin-bottom: 36px;
    }

    .stats-card {
        @include tablet {
            max-height: 135px;
        }

        @include tablet-large {
            max-height: 165px;
        }

        @include desktop {
            max-height: 250px;
        }
    }
}

.home-search-container {
    margin-top: 18px;

    @include tablet-large {
        margin-top: 0;
    }
}

// User

user-list-container {
    position: relative;
}

.user-list {
    position: absolute;
    top: 50px;
    z-index: 1;
    width: 90%;
    padding: 0;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px;

    .user-list-item {
        width: 100%;
        list-style-type: none;
        background-color: #f3f3f3;

        border: 1px solid $color-primary;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        & .name {
            padding: 18px 20px;
            background-color: $color-white;
            border-radius: 8px;
            color: $color-primary;
            font-weight: bold;
        }

        & .roles {
            cursor: pointer;
            padding: 12px 20px;
            border-bottom: 2px solid $color-white;
            display: flex;
            justify-content: space-between;

            &:last-child {
                border-bottom: 0;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &:hover {
                background-color: #f6f6f6;
            }
        }
    }
}

// Impersonnating
.impersonating {
    width: 100%;
    margin-top: 18px;
    padding: 14px 10px;
    font-size: 10px;

    @include tablet {
        height: 100%;
        padding: 20px;
        font-size: 12px;
    }

    @include tablet-large {
        margin-top: 0;
        font-size: 14px;
    }

    .impersonating-header {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 12px;
        line-height: 21px;

        @include tablet {
            font-size: 14px;
        }

        @include tablet-large {
            font-size: 16px;
        }
    }

    .impersonating-disconnect {
        margin-top: 20px;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        color: $color-red;
        cursor: pointer;

        @include tablet {
            font-size: 12px;
        }

        @include tablet-large {
            font-size: 14px;
        }
    }
}
