@import './variables.scss';

.background-primary {
    background-color: $color-primary;
}

.border-bottom-rounded {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.button-info {
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 25px;
    border-radius: 50%;
    background: none;
    color: $color-primary;

    @include mobile-large {
        width: 31px;
        height: 31px;
        font-size: 38px;
    }

    &:before {
        position: relative;
        top: -4px;
        left: -4px;
    }
}

.link {
    color: $color-white;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

hr,
.divider {
    width: 100%;
    height: 1px;
    background-color: $border-color;
}

.divider-middle {
    margin-top: 40px;
    margin-bottom: 40px;
}

.divider-top {
    margin-top: 40px;
}

.remove-padding {
    padding: 0 !important;
}

.background-success {
    background-color: $color-success !important;
}

.background-error {
    background-color: $color-error !important;
}

.background-info {
    background-color: $color-info !important;
}

.color-error {
    color: $color-danger;
}

.error-message {
    font-size: 8px;
    line-height: 1;
    color: $color-danger;

    @include tablet {
        font-size: 14px;
    }
}

.no-padding {
    padding: 0 !important;
}

.no-padding-horizontal {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.order-first-mobile {
    order: -1;

    @include tablet-large {
        order: inherit;
    }
}

// responsive visibility helpers
.show-on-tablet {
    display: none;

    @include tablet {
        display: block;
    }
}

.hide-on-tablet {
    @include tablet {
        display: none;
    }
}
