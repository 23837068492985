@import '../../styles/variables';

.legend {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.is-bottom {
        //display: grid;
        ////grid-column-gap: 12px;
        //grid-template-rows: 1fr 1fr 1fr;
        //grid-auto-flow: column;
        //min-width: 375px;
        //margin-bottom: -10px;
        ////justify-content: space-around;
        //justify-content: space-evenly;

        display: grid;
        grid-column-gap: 12px;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
        margin-bottom: -10px;
        justify-content: space-evenly;
    }

    li {
        display: flex;

        .dot {
            height: 10px;
            width: 10px;
            border-radius: 5px;

            @include tablet {
                height: 20px;
                width: 20px;
                border-radius: 10px;
            }
        }

        .label {
            font-size: 12px;
            //margin-top: 6px;
            margin-top: 0;
            margin-left: 11px;
            margin-bottom: 10px;

            width: min-content;

            @include tablet {
                font-size: 14px;
                line-height: 20px;
                margin-top: 0;
                min-width: 64px;
            }

            @include desktop {
                font-size: 18px;
            }
        }
    }
}

.recharts-label {
    font-size: 10px;
}
