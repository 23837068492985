@import '../../styles/variables';

.nav {
    z-index: 11;
    position: fixed;
    top: 0;
    left: auto;
    right: -150vw;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: $color-primary;
    padding: 22px 0 90px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: $transition-duration;

    @include tablet {
        flex-shrink: 0;
        max-width: 232px;
        height: auto;
        position: static;
        display: inline-block;
        vertical-align: top;
        padding: 13px 0;
        overflow-y: hidden;
        box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &.show {
        right: 0;
    }

    .footer {
        display: flex;
        flex-direction: column;

        .social {
            order: 1;
            text-align: center;
            margin: 22px 0 17px;

            li {
                display: inline-block;
                vertical-align: middle;
                width: 28px;
                height: 28px;
                border: none;
                margin: 0 16px;

                a {
                    line-height: 28px;
                    padding: 0;
                    text-decoration: none;
                    color: $color-white;

                    &:first-child {
                        font-size: 26px;
                    }

                    &:nth-child(2) {
                        font-size: 21px;
                    }

                    &:nth-child(3) {
                        font-size: 24px;
                    }
                }
            }
        }

        @include tablet {
            display: none;
        }
    }

    .footer-links {
        order: 2;
        text-align: center;
        margin: 0 0 21px;

        a {
            font-size: 10px;
            font-weight: 500;
            line-height: 26px;
            text-decoration: none;
            text-transform: uppercase;
            margin: 0 10px;
            color: $color-white;
            cursor: pointer;
        }
    }

    .footer-copy {
        order: 3;
        font-size: 10px;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;
        color: $color-white;
    }
}

.nav-close {
    position: absolute;
    top: 40px;
    left: 25px;
    display: block;
    width: 24px;
    height: 24px;
    background-color: transparent;
    padding: 2px;
    cursor: pointer;
    z-index: 1;
    color: $color-white;

    @include tablet {
        display: none;
    }
}

.nav-logo {
    display: block;
    width: 100%;
    max-width: 180px;
    height: 70px;
    margin: 0 auto 22px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    img {
        display: block;
        width: 100%;
    }

    @include tablet {
        width: 126px;
        height: 75px;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 auto 75px;
    }
}

.nav-user {
    display: block;
    border-bottom: 2px solid $color-white;
    padding: 40px 20px;

    .user-info {
        padding: 0;
    }

    .user-info-avatar,
    .user-info-wrap {
        display: inline-block;
        vertical-align: middle;
    }

    .user-info-logo {
        position: absolute;
        top: auto;
        left: auto;
        right: 44px;
        bottom: -6px;
        width: 46px;
        height: 24px;
    }

    .user-info-avatar {
        margin: 0 23px 0 0;
    }

    .user-info-wrap {
        //padding: 0 0 16px;
        padding: 0;
    }

    .user-info-title,
    .user-info-subtitle {
        font-size: 14px;
        line-height: 17px;
    }

    .user-info-title {
        font-weight: 700;
        text-align: left;
    }

    .user-info-subtitle {
        font-weight: 400;
    }

    @include tablet {
        display: none;
    }
}

.nav-user-left,
.nav-user-right {
    display: inline-block;
    vertical-align: middle;
}

.nav-user-left {
    width: 60%;
}

.nav-user-right {
    width: 40%;
    text-align: right;

    a {
        display: inline-block;
        text-decoration: none;
        padding: 30px 0 0;
        position: relative;

        span {
            font-family: $font-family;
            font-size: 10px;
            font-weight: 700;
            line-height: 120.7%;
            text-transform: uppercase;
            color: $color-white;
        }

        &:before {
            display: inline-block;
            width: 30px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            font-size: 30px;
            font-weight: 400;
            line-height: 30px;
            color: $color-white;
        }
    }
}
