@import '../../styles/variables';

.select {
    position: relative;
    margin-bottom: 24px;
}

.select-input {
    width: 100%;
    text-align: left;
}

.select-list {
    position: absolute;
    top: $input-height;
    left: 0;

    background-color: $color-white;
    border-color: $border-color;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    width: 100%;
    z-index: 1;
    border-radius: 5px;

    > * {
        display: block;
        width: 100%;
        padding: 5px 0px;
        text-align: left;
        background-color: transparent;
    }
}

.form .select {
    width: 75%;
    min-width: 242px;
    margin-bottom: 25px;

    @include tablet-large {
        width: 100%;            
    }
}