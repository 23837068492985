@import '../styles/variables';

.dashboard-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include tablet {
        flex-direction: row;
    }
}

.status-card-container {
    margin-top: 13px;
    display: flex;
    align-items: center;
    gap: 20px 3%;

    @include tablet-large {
        margin: 0;
        flex-direction: column;
    }

    &.status-quarter {
        flex-wrap: wrap;
        gap: 20px 20%;

        @include desktop {
            flex-wrap: nowrap;
            gap: 20px 13px;
        }

        .stats-group {
            height: fit-content;
            flex: 1;
            flex-direction: row;
            word-break: break-all;

            @include desktop {
                word-break: normal;
            }
        }

        .stats-card-sm {
            height: 70px;

            @include tablet-large {
                height: 100%;
            }

            .stats-card-item {

                @include tablet-large {
                    padding: 15px 5px;
                }

                @include desktop {
                    padding: 15px 20px;
                }
            }

            .stats-card-number {
                font-size: 12px;

                @include tablet-large {
                    padding-bottom: 5px;
                    font-size: 18px;
                }
            }

            .stats-card-title {
                font-size: 6px;
                line-height: 1;
                word-break: initial;

                @include tablet-large {
                    font-size: 10px;
                }
            }
        }

        .stats-card-xs {
            flex: 0 0 100%;

            @include tablet-large {
                flex: 0;
            }

            .stats-card-item {
                height: 30px;
            }
        }
    }
}

.single-item {
    .stats-group {
        flex: 0 1 33%;

        @include tablet-large {
            flex: 100%;
        }
    }

    .stats-card-sm {
        max-height: unset;

        .stats-card-item {
            @include tablet-large {
                justify-content: center;
                padding: 25px 20px;
            }
        }

        .stats-card-number {
            margin-top: 1.5em;

            @include tablet-large {
                font-size: 36px;
            }

            @include desktop {
                font-size: 48px;
            }
        }

        .stats-card-title {
            @include tablet-large {
                font-size: 14px;
            }

            @include desktop {
                font-size: 16px;
            }
        }
    }
}

.row-space {
    margin-top: 15px;

    @include tablet-large {
        margin-top: 36px;
    }
}

// .dashboard-left {
//     width: 100%;
//     margin: 0 0 20px;

//     &--reverse {
//         order: 2;
//     }

//     @include tablet {
//         flex: 1 75%;
//         margin: 0 20px 20px 0;

//         &--reverse {
//             order: 1;
//         }
//     }
// }

// .dashboard-right {
//     width: 100%;
//     margin: 0;

//     &--reverse {
//         order: 1;
//     }

//     @include tablet {
//         flex: 1 25%;
//         margin: 0 0 20px;

//         &--reverse {
//             order: 2;
//         }
//     }
// }
