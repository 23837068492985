@import '../../styles/variables';

.menu {
    &.fixed {
        position: fixed;
        flex-direction: row;
        align-items: center;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        background-color: $color-primary;
        border-radius: 10px;
        padding: 12px 20px;
        overflow-x: auto;

        @include tablet {
            display: none;
        }

        [class^='icon-'] {
            display: block;
        }

        li {
            border-top: none;

            &:last-child {
                border-bottom: none;
            }
        }

        .menu-link {
            font-weight: 700;
            border-radius: 12px;
            text-align: center;
            padding: 8px 14px;

            span {
                &:first-child {
                    font-size: 21px;
                }
            }

            &.active {
                padding: 5px 21px;
                [class^='icon-'] {
                    display: none;
                }

                .menu-link-text-mobile {
                    display: block;
                }
            }
        }

        .menu-link-text {
            display: none;
        }
    }

    .menu-link {
        display: block;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        color: $color-white;
        text-decoration: none;
        text-transform: uppercase;
        padding: 29px 25px;

        @include tablet {
            line-height: 37px;
            padding: 8px 8px 8px 68px;
        }

        &:hover,
        &:focus,
        &:active {
            @include tablet {
                background-color: $color-secondary;
            }
        }

        &.active {
            background-color: $color-secondary;
        }
    }

    .menu-link-text-mobile {
        display: none;
        font-size: 10px;
        text-transform: none;
        color: $color-white;
    }

    li {
        display: block;
        width: 100%;
        border-top: 2px solid $color-white;
        transition: $transition-duration;
        position: relative;

        [class^='icon-'] {
            display: none;

            @include tablet {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                left: 25px;
                width: 30px;
                height: 30px;
                font-size: 30px;
                line-height: 30px;
                color: $color-white;
                z-index: 1;
            }
        }

        &:last-child {
            border-bottom: 2px solid $color-white;
        }

        @include tablet {
            margin-top: 20px;
            margin-bottom: 20px;
            border: none;

            &:last-child {
                border: none;
            }
        }
    }
}
