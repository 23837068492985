@import './variables';

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/gotham-book.woff2') format('woff2'),
        url('../assets/fonts/gotham-book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/gotham-medium.woff2') format('woff2'),
        url('../assets/fonts/gotham-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/gotham-bold.woff2') format('woff2'),
        url('../assets/fonts/gotham-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/gotham-bolditalic.woff2') format('woff2'),
        url('../assets/fonts/gotham-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon.eot?j78g9p');
    src: url('../assets/fonts/icomoon.eot?j78g9p#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/icomoon.ttf?j78g9p') format('truetype'),
        url('../assets/fonts/icomoon.woff?j78g9p') format('woff'),
        url('../assets/fonts/icomoon.svg?j78g9p#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-loading.invoices-download:before {
    font-size: 27px;
}

.icon-loading:before {
    content: '\e92a';
}
.icon-power:before {
    content: '\e929';
}
.icon-checkmark-circle:before {
    content: '\e928';
}
.icon-eye-close:before {
    content: '\e927';
}
.icon-guarding:before {
    content: '\e923';
}
.icon-close:before {
    content: '\e924';
}
.icon-arrow-right:before {
    content: '\e925';
}
.icon-arrow-left:before {
    content: '\e926';
}
.icon-menu:before {
    content: '\e922';
}
.icon-brakes:before {
    content: '\e900';
}
.icon-calendar:before {
    content: '\e901';
}
.icon-camera:before {
    content: '\e902';
}
.icon-car:before {
    content: '\e903';
}
.icon-checkmark:before {
    content: '\e904';
}
.icon-chevron-down:before {
    content: '\e905';
}
.icon-dashboard:before {
    content: '\e906';
}
.icon-export:before {
    content: '\e907';
}
.icon-eye-open:before {
    content: '\e908';
}
.icon-facebook:before {
    content: '\e909';
}
.icon-file-export:before {
    content: '\e90a';
}
.icon-home:before {
    content: '\e90b';
}
.icon-import:before {
    content: '\e90c';
}
.icon-info-circle:before {
    content: '\e90d';
}
.icon-instruments:before {
    content: '\e90e';
}
.icon-invoice:before {
    content: '\e90f';
}
.icon-lock:before {
    content: '\e910';
}
.icon-logout:before {
    content: '\e911';
}
.icon-pencil:before {
    content: '\e912';
}
.icon-pneumatics:before {
    content: '\e913';
}
.icon-revision:before {
    content: '\e914';
}
.icon-search:before {
    content: '\e915';
}
.icon-triangle-down:before {
    content: '\e916';
}
.icon-triangle-down.selected {
    &::before {
        color: $color-secondary;
    }
}
.icon-triangle-up:before {
    content: '\e917';
}
.icon-triangle-up.selected {
    &::before {
        color: $color-secondary;
    }
}
.icon-twitter:before {
    content: '\e918';
}
.icon-user:before {
    content: '\e919';
}
.icon-warning:before {
    content: '\e91a';
}
.icon-windscreen .path1:before {
    content: '\e91b';
    color: rgb(0, 0, 0);
}
.icon-windscreen .path2:before {
    content: '\e91c';
    margin-left: -1em;
    color: rgb(0, 0, 0);
}
.icon-windscreen .path3:before {
    content: '\e91d';
    margin-left: -1em;
    color: rgb(0, 0, 0);
}
.icon-windscreen .path4:before {
    content: '\e91e';
    margin-left: -1em;
    color: rgb(0, 0, 0);
}
.icon-windscreen .path5:before {
    content: '\e91f';
    margin-left: -1em;
    color: rgb(0, 0, 0);
}
.icon-windscreen .path6:before {
    content: '\e920';
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.22;
}
.icon-youtube:before {
    content: '\e921';
}
