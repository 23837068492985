@import '../../styles/variables';

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    to {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.button-loading {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 9px;
    font-weight: 700;
    line-height: 1.3;
    font-size: 8px;
    border-radius: $button-border-radius;
    text-transform: uppercase;
    background-color: $button-default-background-color;
    color: $button-default-color;

    @include mobile-large {
        padding: 9px 12px;
        font-size: $button-font-size;
    }

    @include tablet-large {
        padding: 12px 24px;
    }

    [class^='icon-'] {
        font-size: 16px;
        margin-right: 8px;
        line-height: 0;

        @include mobile-large {
            font-size: 20px;
        }

        @include tablet-large {
            font-size: 24px;
        }
    }

    > span {
        flex-shrink: 0;
    }

    &.fullwidth {
        width: 100%;
    }
}

.button-icon {
    display: inline-block;
    vertical-align: middle;
}

.button-sm {
    padding: 8px 30px;
}

.button-skewed {
    transform: skewx(-$button-skewed-angle);
    border-radius: 3px;

    > span {
        display: inline-block;
        vertical-align: middle;
        transform: skew($button-skewed-angle);
        white-space: nowrap;
    }
}

.button-skewed-lg {
    transform: skewx(-$button-skewed-lg-angle);
    border-radius: 3px;

    > span {
        display: inline-block;
        vertical-align: middle;
        transform: skew($button-skewed-lg-angle);
    }
}

.button-large {
    @include tablet-large {
        min-width: 140px;
        padding: 16px 12px;
        font-size: $button-large-font-size;
    }
}

.button-rounded {
    border-radius: $button-rounded-border-radius;
}

.button-disabled {
    background-color: $tab-color;
    color: $button-primary-color;
}

.button-primary {
    background-color: $button-primary-background-color;
    color: $button-primary-color;
}

.button-secondaryAlt {
    background-color: $button-secondaryAlt-background-color;
    color: $button-primary-color;
}

.button-secondary {
    background-color: $button-secondary-background-color;
    color: $button-secondary-color;
}

.button-danger {
    background-color: $button-danger-background-color;
    color: $button-danger-color;
}
