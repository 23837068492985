@import '../../styles/variables';

$checkbox-border-width: 1px;
$checkbox-size: 20px;
$checkbox-color: $color-black;
$checkbox-color-focus: $color-black;

.checkbox {
    display: inline-flex;
    min-height: 16px;
    line-height: 1.5;
    cursor: pointer;

    &.checkbox-sm {
        .checkbox-input {
            ~ .checkbox-box {
                &:after {
                    width: 8px;
                    height: 8px;

                    @include tablet {
                        width: 10px;
                        height: 10px;
                        top: 2px;
                    }
                }

                &:before {
                    width: 6px;
                    height: 6px;
                    font-size: 6px;
                    line-height: 6px;
                    top: 1px;
                    left: 1px;

                    @include tablet {
                        width: 7px;
                        height: 7px;
                        font-size: 7px;
                        line-height: 7px;
                        top: 3px;
                    }
                }
            }
        }
    }
}

.checkbox-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    ~ .checkbox-box {
        display: flex;
        align-items: flex-start;
        position: relative;
        font-size: 18px;
        color: inherit;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            flex-shrink: 0;
            width: $checkbox-size;
            height: $checkbox-size;
            margin-right: 12px;
            overflow: hidden;
            background-color: $color-white;
            border: $checkbox-border-width solid $checkbox-color;

            .checkbox-inverse & {
                background-color: transparent;
                border-color: $color-white;
            }
        }

        &:before {
            display: none;
            z-index: 1;
            position: absolute;
            top: 2px;
            left: 2px;
            color: $color-primary;
        }
    }

    &:checked {
        ~ .checkbox-box {
            &:before {
                display: block;

                .checkbox-inverse & {
                    color: $color-white;
                }
            }
        }
    }

    &:focus {
        ~ .checkbox-box {
            &:after {
                opacity: 0.8;
            }
        }
    }
}

.checkbox-label {
    padding-left: 26px;
    font-size: 13px;
}
