/* stylelint-disable */

// Colors
$color-white: #fff;
$color-black: #000;
$color-red: #bd0000;
$color-dark: #2f2f2f;
$color-blue: #0245cc;
$color-green: #1ae540;

$color-primary: #001f5e;
$color-secondary: #00a8ff;

$color-text: $color-dark;

$page-background-color: #fcfcfc;

// States
$color-success: rgba(0, 255, 71, 0.2);
$color-danger: $color-red;
$color-error: rgba(189, 0, 0, 0.2);
$color-info: rgba(0, 168, 255, 0.2);

// Decorative global styles
$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$border-color: #e5e5e5;
$border-radius: 10px;

// Fonts
$font-family: 'Gotham', serif;
$font-size: 16px;
$font-weight: 400;
$line-height: 1;

// Container
$container-width-mobile-large: 414px;
$container-width-tablet: 768px;
$container-width-tablet-medium: 992px;
$container-width-tablet-large: 1200px;
$container-width-desktop: 1600px;

// Components

// Buttons
$button-default-background-color: $color-white;
$button-default-color: $color-primary;

$button-primary-background-color: $color-primary;
$button-primary-color: $color-white;

$button-secondary-background-color: $color-secondary;
$button-secondaryAlt-background-color: #00a7d6;

$button-secondary-color: $color-white;

$button-danger-background-color: $color-danger;
$button-danger-color: $color-white;

$button-font-size: 10px;
$button-large-font-size: 14px;

$button-skewed-angle: 20deg;
$button-skewed-lg-angle: 30deg;

$button-border-radius: 5px;
$button-rounded-border-radius: $border-radius;

// Inputs
$input-font-size: 10px;
$input-font-size-desktop: 14px;
$input-height: 40px;
$input-padding-horizontal: 20px;
$input-padding-vertical: 12px;
$input-padding-horizontal-desktop: 20px;
$input-padding-vertical-desktop: 10px;
$input-border-color: $color-black;
$input-border-radius: 5px;
$input-color: $color-dark;
$input-error-message-color: $color-danger;
$input-error-border-color: $color-danger;
$input-disabled-background-color: $border-color;
$input-disabled-border-color: $border-color;
$input-icon-width: 37px;
$input-flat-background-color: $color-white;
$input-file-icon-background-color: #eaeaea;
$input-file-icon-color: $color-dark;

$label-color: $color-dark;

$placeholder-color: $color-dark;

// Cards
$card-background-color: $color-white;
$card-shadow: $box-shadow;
$card-border-radius: $border-radius;

// Modal
$modal-shadow: $box-shadow;
$modal-overlay: rgba(0, 0, 0, 0.1);

// Pagination
$pagination-link-color: $color-dark;
$pagination-link-hover-color: $color-secondary;

// Tabs
$tab-color: #c4c4c4;
$tab-active-color: $color-primary;
$tab-border-width: 1px;
$tab-border-color: transparent;
$tab-border-active-color: $color-primary;
$tab-vertical-background-color: $color-white;
$tab-vertical-border-color: transparent;
$tab-vertical-border-width: 3px;
$tab-vertical-active-background-color: #fafafa;
$tab-vertical-color: $color-dark;

// Table
$table-row-odd-background-color: #fafafa;
$table-row-even-background-color: $color-white;
$table-row-selected-background-color: #dcdbdb;

// Transitions
$transition-duration: 0.2s;

// Mixins
@mixin mobile-large {
    @media (min-width: $container-width-mobile-large) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $container-width-tablet) {
        @content;
    }
}

@mixin tablet-medium {
    @media (min-width: $container-width-tablet-medium) {
        @content;
    }
}

@mixin tablet-large {
    @media (min-width: $container-width-tablet-large) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $container-width-desktop) {
        @content;
    }
}

@mixin custom-media($size) {
    @media (min-width: $size) {
        @content;
    }
}

$transition-property: all;
$transition-duration: 0.25s;
$transition-timing-function: ease-in-out;
$transition-delay: 0s;
$transition: $transition-property $transition-duration
    $transition-timing-function;

@mixin transition {
    transition: $transition;
}
