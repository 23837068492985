@import './variables';

body {
    -webkit-tap-highlight-color: transparent;
}

button,
button:focus,
a,
a:focus,
input,
input:focus,
p,
p:focus,
div,
div:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
}

html {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}

html,
body,
#root,
.page {
    height: 100%;
    min-height: 100vh;
}

.page {
    display: flex;
    flex-direction: column;

    @include tablet {
        flex-direction: row;
    }
}

.main-wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
}

ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-position: inside;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

h2 {
    font-size: 18px;
    margin-bottom: 23px;
}

p {
    color: $color-text;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    font-family: inherit;
    line-height: inherit;
    border: none;
    border-radius: 0;
    cursor: pointer;
    appearance: none;
}

input {
    appearance: none;

    &::placeholder {
        color: $placeholder-color;
    }
}

svg {
    width: inherit;
    height: inherit;
    fill: currentColor;
}

b,
strong {
    font-weight: 700;
}

.main {
    flex-grow: 1;
    padding: 20px 20px 120px 20px;
    overflow-y: auto;
    background-color: $page-background-color;

    @include tablet {
        padding: 50px 40px;
    }
}

.tablet-large {
    display: none;

    @include tablet-large {
        display: block;
    }
}

.mobile {
    display: block;

    @include tablet-large {
        display: none;
    }
}

.recharts-rectangle.recharts-tooltip-cursor {
    display: none;
}
