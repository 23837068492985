@import '../styles/variables';

.content {
    position: relative;

    @include tablet {
        margin-top: 85px;
    }
}

.filter-box {
    padding: 18px 46px 0;

    @include tablet {
        padding: 0;
        position: absolute;
        top: -17px;
        transform: translateY(-100%);
    }

    .comparison-card {
        width: 130px;
        padding: 10px 15px;
        background-color: $color-primary;
        color: $color-white;
        font-family: $font-family;
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet {
            width: fit-content;
            padding: 25px;
            font-size: 18px;
            flex-direction: row;
        }

        .comparison-number {
            margin-top: 15px;
            font-size: 24px;

            @include tablet {
                margin-top: 0;
                margin-left: 50px;
            }
        }
    }
}

.table-header .table-header-search {
    max-width: unset;
    padding-left: 34px;
    padding-right: 34px;
    margin: 0;

    @include tablet-large {
        max-width: 635px;
    }
}