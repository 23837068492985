@import '../../styles/variables';

.calendar {
    position: relative;

    .popup {
        position: absolute;
        color: $color-primary;
        font-style: normal;
        font-weight: bold;
        z-index: 1;
        right: 0;
        text-align: center;
        background-color: $color-white;
        //width: 375px;
        width: calc(100vw - 40px);
        max-width: 375px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 35px;
        display: none;

        @include tablet {
            width: 375px;
        }

        &.show {
            display: block;
            border-radius: 8px;
        }

        .cursor-pointer {
            cursor: pointer;
        }

        .current {
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;

            .prev-month {
                width: 14.25%;
            }

            .display-date {
                flex: 1;
                width: 100%;
                font-size: 18px;
            }

            .next-month {
                width: 14.25%;
            }
        }

        .dates-wrap {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
        }

        .day-wrap {
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;

            .case {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 27px;

                p {
                    margin: 0px;
                }

                &.selected {
                    background-color: $color-primary;
                    // border-radius: 10px;

                    p {
                        color: $color-white;
                    }
                }
                &.disabled p {
                    color: $tab-color;
                }

                &.days {
                    p {
                        color: $color-primary;
                    }
                }
                &.start {
                    border-radius: 15px 0px 0px 15px;
                }
                &.end {
                    border-radius: 0px 15px 15px 0px;
                }
            }
        }
        .action {
            display: flex;
            justify-content: space-around;
            margin-top: 15px;
            width: 100%;
            height: 40px;

            .cancel,
            .validate {
                padding: 12px 28px;
                font-style: normal;
                font-weight: bold;
                border-radius: 5px;
            }

            .cancel {
                color: $color-primary;
                font-size: 14px;
                background-color: $color-white;
            }

            .validate {
                color: $color-white;
                font-size: 14px;
                background-color: $color-primary;
            }
        }
    }
}
