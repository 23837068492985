@import '../../styles/variables';

.table-wrapper {
    //overflow-x: inherit;
    //overflow-x: visible;
    //
    ////   &:has( + .empty-data) {
    ////     overflow-x: unset;
    ////   }

    overflow-x: scroll;

    @include custom-media(980px) {
        overflow-x: visible;
    }

    &:has(+ .empty-data) {
        overflow-x: visible;
    }
}

.table {
    width: 100%;
    border-collapse: collapse;

    .code-td {
        min-width: 100px;
        line-height: 1px;
    }

    .code-td-2 {
        min-width: 375px;
        line-height: 1px;
    }

    caption {
        padding: 15px 0;
        font-weight: 700;
        font-size: 14px;
        text-align: center;

        @include tablet-large {
            padding: 30px 0 20px;
            font-size: 24px;
        }
    }

    td {
        padding: 11px 20px;
        font-size: 8px;
        text-align: center;
        color: $color-dark;
        word-break: keep-all;

        &:not(.check) {
            min-width: 100px;
        }

        @include tablet {
            font-size: 12px;
        }

        @include tablet-large {
            font-size: 18px;
        }

        @include desktop {
            padding: 15px 40px;
        }
    }

    th {
        padding: 12px;
        font-weight: 700;
        font-size: 10px;

        &:not(.check) {
            min-width: 100px;
        }

        @include tablet {
            font-size: 14px;
        }

        @include tablet-large {
            font-size: 18px;
        }

        &.check {
            width: 40px;
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: $table-row-odd-background-color;
            }

            &:nth-child(even) {
                background-color: $table-row-even-background-color;
            }

            // .checkbox {
            //     visibility: hidden;
            // }

            &.selected {
                background-color: $table-row-selected-background-color;

                .checkbox {
                    visibility: visible;
                }
            }
        }

        tr:hover > td > .checkbox {
            visibility: visible;
        }

        td {
            word-break: keep-all;

            &.check {
                width: 40px;
            }
        }
    }
}

.table-small {
    td {
        @include tablet-large {
            font-size: 14px;
        }
    }

    th {
        @include tablet-large {
            font-size: 16px;
        }
    }
}

.table-head-sort {
    display: inline-block;
    vertical-align: middle;
    padding-left: 6px;

    button {
        display: block;
        padding: 0;
        font-size: 7px;
        background: none;

        @include tablet-large {
            font-size: 10px;
        }

        // &:hover,
        // &:focus,
        // &:active {
        //     color: $color-secondary;
        // }
    }
}

.table-head-title {
    //word-break: break-all;
    display: inline-block;
    vertical-align: middle;
}

.table-head-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    &.sort {
        cursor: pointer;
    }
}

.empty-data {
    width: 100%;
    padding: 100px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;

    & .icon-laoding {
        width: 50px;
    }

    @include tablet {
        padding: 200px 0px;
    }

    button {
        margin-top: 10px;
    }
}
