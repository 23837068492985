@import '../../styles/variables';

.button-input {
    position: relative;

    input {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        opacity: 0;
        z-index: 1;
    }
}

.error-list {
    max-height: 200px;
    overflow-x: scroll;
}
