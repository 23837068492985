@import '../../styles/variables';

.tabs {
    // extra wrapper for styling
    .tabs-wrap {
        display: flex;
        width: 100%;

        > * {
            @include tablet-large {
                width: 50%;
            }
        }
    }
}

.tabs-vertical {
    .tabs-wrap {
        @include tablet {
            flex-direction: column;
        }
    }

    .tab {
        @include tablet {
            flex-grow: 0;
            width: 100%;
            padding: 30px 10px;
            font-size: 14px;
            border-bottom: 0;
            border-left: $tab-vertical-border-width solid $tab-border-color;
            text-transform: uppercase;
            background-color: $tab-vertical-background-color;
            color: $tab-vertical-color;

            &.active {
                background-color: $tab-vertical-active-background-color;
                border-left-color: $tab-border-active-color;
            }
        }
    }
}
