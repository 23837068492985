@import '../../styles/variables';

.header {
    z-index: 10;
    position: relative;
    display: block;
    width: 100%;
    height: 75px;
    background-color: $color-secondary;
    padding: 0 20px;
    font-size: 0;
    flex-shrink: 0;

    h2 {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 70px);
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: $color-white;
        margin: 0;
        padding: 0 20px 0 20px;

        & svg {
            height: 24px;
            min-width: 24px;
            width: fit-content;
            margin-bottom: -4px;
            margin-right: 5px;
        }

        &.clickable {
            cursor: pointer;
        }

        span {
            font-weight: 400;
        }

        @include tablet {
            width: 40%;
            font-size: 18px;
            padding: 0 10px;
        }

        @include tablet-large {
            width: 60%;
            font-size: 24px;
            padding: 0 35px;
        }
    }

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
    }

    @include tablet {
        height: 100px;
        padding: 0 40px;
    }
}

.header-menu {
    display: inline-block;
    vertical-align: middle;

    button {
        width: 24px;
        height: 24px;
        background-color: transparent;
        padding: 0;
        cursor: pointer;

        &:before {
            font-size: 18px;
            line-height: 24px;
            color: $color-white;
        }
    }

    @include tablet {
        display: none;
    }
}
