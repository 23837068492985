@import '../../styles/variables';

.dropdown {
    position: relative;
    background-color: transparent;
    border: none;
    padding: 0;
}

.dropdown-body {
    z-index: 1;
    display: none;
    position: absolute;
    top: 20px;
    left: auto;
    right: 0;
    width: auto;
    height: auto;
    text-align: center;
    max-width: 118px;
    padding: 8px;
    background-color: $color-white;
    border-radius: 0 0 5px 5px;

    &.open {
        display: block;
    }

    @include tablet {
        top: 25px;
        width: 170px;
        max-width: 170px;
        padding: 20px 12px;
    }
}

.dropdown-header {
    display: inline-block;
    padding: 5px 5px;
    min-width: 75px;
    font-family: $font-family;
    font-size: 8px;
    font-weight: 700;
    line-height: 1;
    color: $color-dark;

    @include tablet {
        min-width: 170px;
        padding: 8px;
        font-size: 12px;
    }
}

.dropdown-icon {
    display: inline-block;
    vertical-align: middle;
    padding-left: 6px;
    font-size: 11px;

    @include tablet {
        font-size: 15px;
    }
}

.dropdown-title {
    font-size: 8px;
    font-weight: 700;
    line-height: 12px;
    color: $color-dark;
    margin: 0 0 8px;

    @include tablet {
        font-size: 12px;
        line-height: 18px;
    }

    span {
        display: block;
        margin-top: 8px;
        font-size: 6px;
        line-height: 9px;

        @include tablet {
            font-size: 10px;
            line-height: 15px;
        }
    }

    &--input {
        text-align: left;

        span {
            display: flex;
        }
    }
}

.dropdown-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dropdown-button {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    color: $color-dark;
    text-decoration: underline;
    padding: 0;
    background: none;
    cursor: pointer;

    @include tablet {
        font-size: 10px;
        line-height: 14px;

        &:hover {
            text-decoration: none;
        }
    }
}

.dropdown-input {
    display: inline-block;
    width: 11px;
    font-size: 6px;
    font-weight: 700;
    line-height: 7px;
    color: $color-dark;
    border: none;
    border-bottom: 1px solid $color-dark;
    margin: 0 3px;
    padding: 0;
    outline: none;

    @include tablet {
        width: 18px;
        font-size: 10px;
        line-height: 11px;
        margin: 0 4px;
    }
}

.dropdown-calendar {
    font-size: 6px;
    line-height: 9px;
    color: $color-dark;
    margin: 0 0 0 8px;
    padding: 0;
    background: none;
    cursor: pointer;

    @include tablet {
        font-size: 16px;
        line-height: 15px;
        margin: 0 0 0 auto;
    }
}
