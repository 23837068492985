@import '../../styles/variables';

.dropdown-search {
    text-align: center;

    .dropdown {
        display: inline-block;
        width: 100%;
        max-width: 112px;

        @include tablet {
            max-width: 140px;
        }
    }

    .dropdown-header {
        font-size: 10px;

        @include tablet {
            font-size: 14px;
        }

        @include tablet-large {
            font-size: 18px;
        }
    }

    .dropdown-body {
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
        width: 126px;
        max-width: 126px;

        @include tablet {
            width: 170px;
            max-width: 170px;
            padding: 9px 9px 20px;
        }
    }

    .dropdown-search-form {
        display: block;
        width: 100%;
    }

    .dropdown-search-scrollable {
        display: block;
        width: calc(100% + 8px);
        max-height: 116px;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        margin: 0 0 6px;
        padding: 38px 0 0;

        @include tablet {
            width: calc(100% + 9px);
            max-height: 200px;
            margin: 0 0 20px;
            padding: 46px 0 0;
        }
    }

    .dropdown-search-wrap {
        z-index: 2;
        position: fixed;
        top: 7px;
        left: 7px;
        width: calc(100% - 22px);
        background-color: $color-white;
        border: 1px solid $color-primary;
        border-radius: 5px;
        margin: 0 0 12px;
        padding: 9px 7px;

        @include tablet {
            top: 8px;
            left: 8px;
            padding: 11px 10px;
        }
    }

    .dropdown-search-input,
    .dropdown-search-result {
        font-size: 6px;
        font-weight: normal;
        line-height: 1;

        @include tablet {
            font-size: 8px;
        }
    }

    .dropdown-search-input {
        display: block;
        color: $color-black;
        border: none;
        margin: 0;
        padding: 0;
        outline: none;

        &::placeholder {
            color: $tab-color;
        }
    }

    .dropdown-search-result {
        color: $tab-color;
        margin: 0 0 6px;

        span {
            color: $color-black;
        }

        &:last-child {
            margin: 0;
        }
    }

    .dropdown-search-autocomplete {
        margin: 10px 0 0;
    }

    .checkbox {
        display: flex;

        @include tablet {
            margin: 0 0 16px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .checkbox-label {
        font-size: 8px;
        font-weight: normal;
        line-height: 1;
        padding-left: 15px;

        @include tablet {
            font-size: 14px;
            padding-left: 24px;
        }
    }

    .button {
        max-width: calc(100% - 10px);
        font-size: 8px;

        @include tablet {
            font-size: 10px;
            max-width: 100px;
        }
    }
}
