@import '../../styles/variables';

.tab {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 14px 15px;
    font-weight: 700;
    font-size: 10px;
    background: none;
    border: 0;
    text-align: center;
    border-bottom: $tab-border-width solid $tab-border-color;
    color: $tab-color;

    @include tablet {
        font-size: 18px;
    }

    & > button {
        color: #001f5e;
        position: absolute;
        left: 5px;
        font-size: 18px;
        font-weight: bold;
        background: transparent;

        & svg {
            height: 18px;
        }
    }

    &.active {
        border-bottom-color: $tab-border-active-color;
        color: $tab-active-color;
    }
}
