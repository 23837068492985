@import '../../styles/variables';

:root {
    --75width: 100%;
    --100width: 100%;
    --flexWrap: wrap;

    @media (min-width: 600px) {
        --flexWrap: nowrap;
    }

    @include tablet-large {
        --75width: calc(75% - 5px);
    }
}

.panel {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    margin: 0 0 16px;

    @include tablet {
        margin: 0 0 18px;
    }

    .panel-button {
        width: 10px;
        height: 10px;
        font-size: 10px;
        line-height: 1;
        border: none;
        background-color: rgba(0, 0, 0, 0);
        margin: 0;
        padding: 0;
        cursor: pointer;

        @include tablet {
            width: 16px;
            height: 16px;
            font-size: 16px;
        }
    }

    .panel-calendar {
        padding: 5px 6px;

        @include tablet {
            padding: 8px 11px;
        }
    }

    .panel-wrap,
    .panel-line,
    .panel-calendar,
    .panel-dropdown {
        display: inline-flex;
        align-items: center;
    }

    .panel-label,
    .panel-button {
        display: inline-block;
        vertical-align: middle;
    }

    .panel-input {
        display: none;
    }

    .panel-label {
        font-weight: 700;
        font-size: 12px;
        line-height: 1;
        white-space: nowrap;
        color: $color-black;
        margin: 0 8px 0 0;

        @include tablet {
            font-size: 18px;
            margin: 0 14px 0 0;
        }
    }

    .panel-lable-icon {
        margin-right: 14px;display:none;
    }

    .panel-line {
        content: '';
        width: 100%;
        height: 2px;
        background-color: $input-disabled-background-color;
    }

    .panel-calendar,
    .dropdown {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 75px;
        font-weight: 700;
        font-size: 8px;
        line-height: 1;
        color: $color-dark;
        text-align: center;
        justify-content: center;
        white-space: nowrap;
        background-color: $input-disabled-background-color;
        border-radius: 5px;
        //margin: 0 0 0 4px;
        margin: 1px 0 1px 4px;
        position: relative;
        cursor: pointer;

        @include tablet {
            font-size: 12px;
            margin: 0 0 0 19px;
        }
    }
}
