@import '../../styles/variables';

.stats-card {
    width: 100%;
    height: 100%;
    display: block;
    flex: 0 0 30%;
    text-align: center;

    &.cursor-pointer {
        cursor: pointer;
    }

    &.stats-card-inline {
        display: inline-flex;

        .stats-card-item {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 78px;

            @include tablet {
                flex-direction: row;
            }
        }

        .stats-card-title {
            font-size: 10px;

            @include tablet {
                font-size: 18px;
                margin: 0 50px 0 0;
            }
        }

        .stats-card-number {
            font-size: 24px;
        }
    }

    &.stats-card-order-3 {
        order: 3;

        @include tablet-large {
            order: unset;
        }
    }

    .stats-card-item {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        vertical-align: top;
        width: 100%;
        height: 100%;
        padding: 14px 5px;
        background-color: $color-primary;
        border-radius: 10px;
        box-shadow: $card-shadow;
        overflow: hidden;

        @include mobile-large {
            padding: 14px 10px;
        }

        @include tablet {
            padding: 20px;
        }

        @include tablet-large {
            width: 100%;
        }

        @include desktop {
            padding: 40px 20px;
        }
    }

    .stats-card-title {
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        color: $color-white;

        @include tablet {
            font-size: 12px;
        }

        @include tablet-large {
            font-size: 14px;
        }

        @include desktop {
            font-size: 20px;
        }
    }

    .stats-card-number {
        margin-top: 1em;
        font-weight: 700;
        font-size: 18px;
        color: $color-white;

        @include tablet {
            font-size: 32px;
        }

        @include tablet-large {
            font-size: 48px;
        }

        @include desktop {
            font-size: 64px;
        }
    }

    @include tablet {
    }
}

.stats-card-secondary {
    .stats-card-item {
        background-color: $color-secondary;
    }
}

.stats-card-updated {
    .stats-card-item {
        background-color: $color-blue;
    }
}

.stats-card-xs {
    height: 30px;

    @include tablet-large {
        flex: 0 0 35px;
    }

    .stats-card-item {
        height: 100%;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
    }

    .stats-card-title {
        font-size: 8px;

        @include tablet {
            font-size: 12px;
        }

        @include tablet-large {
            font-size: 14px;
        }

        @include desktop {
            font-size: 16px;
        }
    }

    .stats-card-number {
        margin-top: 0;
    }
}

.stats-group {
    height: 100%;
    display: flex;
    gap: 20px 13px;
    flex: 1;

    @include tablet-large {
        width: 100%;
        flex-direction: column;
    }
}

.stats-card-sm {
    flex: 1;

    .stats-card-item {
        width: 100%;
        padding: 10px;
        display: flex;

        @include tablet {
            padding: 15px 20px;
        }

        @include tablet-large {
            height: 100%;
        }
    }

    .stats-card-number {
        margin-top: 0;
        font-size: 14px;

        @include tablet {
            font-size: 16px;
        }

        @include tablet-large {
            font-size: 20px;
        }

        @include desktop {
            font-size: 36px;
        }
    }

    .stats-card-title {
        font-size: 8px;

        @include tablet {
            font-size: 10px;
        }

        @include tablet-large {
            font-size: 12px;
        }

        @include desktop {
            font-size: 14px;
        }
    }
}

.stats-card-diff {
    font-size: 8px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 0 3px;

    @include tablet {
        font-size: 14px;
        margin: 0 0 0 5px;
    }
}

.stats-card-decrease {
    color: $color-red;
}

.stats-card-increase {
    color: $color-green;
}
