@import './variables.scss';

.card {
    display: inline-block;
    padding: 27px;
    background-color: $card-background-color;
    box-shadow: $card-shadow;
    border-radius: $card-border-radius;

    &.border-bottom-none {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.border-top-none {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    @include tablet-large {
        padding: 45px 75px;
    }
}

.card-primary {
    background-color: $color-primary;
    color: $color-white;
}

.table-header {
    padding-top: 32px;
    padding-right: 12px;
    padding-bottom: 32px;
    padding-left: 12px;

    @include tablet-large {
        padding-top: 32px;
        padding-right: 40px;
        padding-bottom: 32px;
        padding-left: 40px;
    }

    &.center {
        justify-content: center;
    }

    .input-item {
        width: 100%;

        @include tablet-large {
            margin-bottom: 0;
        }
    }

    .table-header-search {
        max-width: 282px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 16px;

        @include tablet-large {
            max-width: 635px;
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
        }
    }

    .filters-wrap {
        display: flex;
        flex-direction: column;

        @include tablet-large {
            flex-direction: row;
        }
    }
}

.table-header-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.table-header-download {
    margin-top: 25px;
    text-align: center;

    @include tablet {
        margin-top: 0;
        text-align: left;
    }
}

.empty-data {
    text-align: center;
    width: 100%;
}
