@import '../../styles/variables';

.user-info {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 0 0 16px;

    @include tablet {
        width: 60%;
        text-align: right;
        padding: 0;
    }

    @include tablet-large {
        width: 40%;
    }
}

.user-info-file {
    position: absolute;
    right: 0;
    bottom: 6px;
}

.user-info-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .user-info-avatar {
        position: relative;
        width: 110px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
    }

    .user-info-avatar-image {
        width: 110px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
    }

    .user-info-logo {
        order: 1;
        position: static;
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: 72px;
        height: 36px;

        @include tablet {
            width: 124px;
            max-width: 124px;
            height: 60px;
        }
    }

    .user-info-logo-image {
        max-width: none;
        width: inherit;
        height: inherit;
    }

    .user-info-logout {
        display: block;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
        border-radius: 0;
        background: none;
        color: $color-danger;

        [class^='icon-'] {
            vertical-align: middle;
            font-size: 16px;
            margin-right: 6px;
        }

        @include tablet {
            display: none;
        }
    }

    .user-info-logout-text {
        vertical-align: middle;
        font-size: 10px;

        @include tablet {
            display: none;
        }
    }

    .user-info-subtitle {
        display: none;
    }

    .user-info-title-link {
        display: block;
        margin-bottom: 4px;
        font-size: 18px;
        text-transform: uppercase;
        color: $color-dark;

        @include tablet-large {
            font-size: 24px;
            line-height: 1.5;
        }
    }

    .user-info-wrap {
        order: 2;
        display: block;
    }
}

.user-info-logo {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: auto;
    bottom: 0;
    display: block;
    width: 30px;
    height: 16px;

    @include tablet {
        display: inline-block;
        vertical-align: top;
        position: static;
        width: 75px;
        height: 40px;
        margin: 0 6px 0 0;
    }
}

.user-info-logo-image {
    display: block;
    width: inherit;
    height: inherit;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.user-info-logout {
    display: none;
}

.user-info-avatar {
    position: relative;
    display: block;
    width: 45px;
    height: 45px;

    @include tablet {
        display: inline-block;
        vertical-align: top;
        width: 45px;
        height: 45px;
        margin: 0 8px 0 0;
    }

    @include tablet-large {
        margin: 0 17px 0 0;
    }
}

.user-info-avatar-image {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.user-info-wrap {
    display: none;
    text-decoration: none;

    @include tablet {
        display: inline-block;
        vertical-align: top;
    }
}

.user-info-title-link,
.user-info-subtitle {
    color: $color-white;

    @include tablet {
        font-size: 15px;
        line-height: 21px;
        margin: 0;
    }

    @include tablet-large {
        font-size: 18px;
    }
}

.user-info-title {
    text-align: center;

    @include tablet {
        text-align: right;
    }
}

.user-info-title-link {
    font-weight: 700;
}

.user-info-subtitle {
    font-weight: 400;
}
