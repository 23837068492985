@import '../../styles/variables';

.rodal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rodal-dialog {
    position: relative;
    //max-width: 100%;
    max-width: 70%;
    padding: 32px 24px 24px;
    border-radius: 10px;
    box-shadow: $modal-shadow;

    form {
        max-width: 460px;
        margin-right: auto;
        margin-left: auto;
    }

    .modal-icon {
        display: block;
        width: 42px;
        height: 42px;
        font-size: 50px;
        margin-right: auto;
        margin-bottom: 32px;
        margin-left: auto;
        color: $color-primary; // default modal icon color

        &:before {
            position: relative;
            top: -4px;
            left: -4px;
        }
    }

    .button {
        //margin-right: 20px;
        //margin-bottom: 24px;
        //margin-left: 20px;

        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-bottom: 24px;
        margin-left: auto;

        @include tablet {
            display: inline-flex;
            margin-right: 20px;
            margin-left: 20px;
        }
    }

    .icon-info-circle {
        color: $color-primary;
    }

    .icon-warning {
        color: $color-danger;
    }

    .modal-body {
        text-align: center;
    }

    h3,
    p {
        font-size: 18px;
    }

    ul,
    ol {
        margin-bottom: 18px;
    }
}

.rodal-mask {
    background: $modal-overlay;
}
