@import '../styles/variables';

.login-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-image: url('../assets//images/login-bg.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

.login-form {
    max-width: 690px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 64px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $color-white;
    background-color: rgba(0, 31, 94, 0.95);

    @include tablet {
        padding: 32px 118px;
        background-color: $color-primary;
    }

    h2 {
        margin-bottom: 24px;
        font-size: 18px;
        font-style: italic;
        text-transform: uppercase;
        text-align: center;
        color: $color-white;

        @include tablet {
            font-size: 28px;
            margin-bottom: 46px;
        }
    }

    p {
        margin: 12px 0 16px;
        font-size: 13px;
        color: $color-white;

        @include tablet {
            margin: 24px 0 16px;
        }
    }

    .link {
        display: inline-block;
        margin-top: 20px;
        font-size: 11px;
        text-decoration: none;
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
        cursor: pointer;
    }

    .input-error-icon {
        font-size: 24px;

        @include tablet-large {
            font-size: 36px;
        }
    }

    .input-error-message {
        color: #e18d88;
    }
}

.login-logo {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 24px;
    width: 242px;

    @include tablet {
        width: 204px;
        margin-bottom: 10px;
    }
}
