@import '../../styles/variables';

.input {
    padding: $input-padding-vertical $input-padding-horizontal;
    background: none;
    border: 1px solid $input-border-color;
    border-radius: 5px;
    font-size: $input-font-size;
    line-height: 1.3;
    color: $input-color;

    @include tablet-large {
        max-width: 100%;
        padding: $input-padding-vertical-desktop
            $input-padding-horizontal-desktop;
        font-size: $input-font-size-desktop;
    }

    &.error {
        border-color: $input-error-border-color;
    }

    &.flat {
        padding: 12px $input-padding-horizontal;
        border-radius: 0;
        background-color: $input-flat-background-color;
    }

    &.fullwidth {
        width: 100%;
    }

    &:disabled {
        background-color: $input-disabled-background-color;
        border-color: $input-disabled-border-color;
    }
}

input.input-large {
    padding: 8px 13px;
    border-width: 2px;

    @include tablet-large {
        padding: 14px 13px;
    }

    + [class^='icon-'] {
        width: 33px;
        height: 100%;

        @include tablet-large {
            width: 50px;
        }
    }
}

input.input-primary {
    border-color: $color-primary;

    + [class^='icon-'] {
        background-color: $color-primary;
        color: $color-white;
    }
}

.input-item {
    width: 100%;
    margin-bottom: 25px;
    position: relative;

    &.icon-left {
        .input {
            padding-left: $input-icon-width;
        }

        [class^='icon-'] {
            left: 0;
            border-top-left-radius: $input-border-radius;
            border-bottom-left-radius: $input-border-radius;
        }
    }

    &.icon-right {
        .input {
            padding-right: $input-icon-width;
        }

        [class^='icon-'] {
            right: 0;
            border-top-right-radius: $input-border-radius;
            border-bottom-right-radius: $input-border-radius;
        }
    }

    [class^='icon-'] {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $input-icon-width;
        height: 100%;
        font-size: 16px;

        @include tablet-large {
            font-size: 24px;
        }
    }

    .input-toggle-password {
        position: absolute;
        top: 0;
        right: 0;
        width: $input-icon-width;
        height: 100%;
        padding: 10px;
        font-size: 18px;
        background: none;

        [class^='icon-'] {
            font-size: 14px;
        }

        .icon-eye-close {
            font-size: 12px;
        }
    }
}

.long-error.input-item {
    margin-bottom: 40px;

    @include mobile-large {
        margin-bottom: 50px;
    }

    @include tablet-medium {
        margin-bottom: 40px;
    }

    @include desktop {
        margin-bottom: 35px;
    }
}

.input-error-icon {
    position: absolute;
    //left: calc(100% + 3px);
    left: -30px;
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1.2;
    color: $input-error-message-color;

    @include tablet-large {
        left: -40px;
        font-size: 36px;
    }
}

.input-error-message {
    font-size: 8px;
    padding: 6px 0;
    font-family: $font-family;
    color: $input-error-message-color;
    position: absolute;
    left: 20px;
    bottom: 0;
    transform: translateY(100%);

    @include mobile-large {
        font-size: 12px;
    }
}

.label {
    display: block;
    margin-bottom: 8px;
    font-size: 10px;
    color: $label-color;

    @include tablet {
        margin-bottom: 20px;
        font-size: 13px;
    }
}

.label-inline {
    font-size: 10px;
    margin-bottom: 8px;

    @include tablet-medium {
        padding: 12px 0;
        margin-bottom: 0;
    }

    @include tablet-large {
        font-size: 14px;
    }
}

.form {
    .input-item {
        width: 75%;
        min-width: 242px;

        @include tablet-large {
            width: 100%;
        }
    }

    .input-error-icon {
        left: calc(100% + 8px);
        font-size: 24px;

        @include tablet-large {
            left: -46px;
        }
    }

    .input-error-message {
        font-size: 8px;

        @include tablet-large {
            width: 100%;
            font-size: 14px;
            left: calc(100% + 6px);
            top: 50%;
            transform: translateY(-50%);
        }

    }
}
