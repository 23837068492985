@import '../../styles/variables';

.quick-access {
    display: block;
    width: 100%;

    .quick-access-list {
        width: calc(100% + 20px);
        margin: 0 -10px;
        padding: 0;
        list-style-type: none;
        font-size: 0;

        &.card {
            box-shadow: none;
        }

        @include mobile-large {
            width: calc(100% + 24px);
            margin: 0 -12px;
        }

        @include tablet-large {
            width: 100%;
            border-radius: 10px;
            background-color: $color-white;
            margin: 0;

            &.card {
                box-shadow: $card-shadow;
            }
        }
    }

    .quick-access-item {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        margin: 0 0 20px;
        padding-right: 3px;
        padding-left: 3px;
        text-align: center;

        @include mobile-large {
            margin: 0 0 20px;
            padding-right: 12px;
            padding-left: 12px;
        }

        @include tablet-large {
            width: 100%;
            border-bottom: 2px solid $input-disabled-background-color;
            margin: 0;

            &:last-child {
                border-bottom: none;
            }
        }

        &.active {
            @include tablet-large {
                background-color: $color-secondary;
                color: $color-white;
            }

            .quick-access-icon {
                background-color: $color-secondary;

                @include tablet-large {
                    background-color: $color-primary;
                }
            }
        }

        &:first-child {
            border-top-left-radius: $card-border-radius;
            border-top-right-radius: $card-border-radius;

            .quick-access-link {
                border-top-left-radius: $card-border-radius;
                border-top-right-radius: $card-border-radius;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            border-bottom-left-radius: $card-border-radius;
            border-bottom-right-radius: $card-border-radius;

            .quick-access-link {
                border-bottom-left-radius: $card-border-radius;
                border-bottom-right-radius: $card-border-radius;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    .quick-access-icon {
        display: inline-block;
        width: 54px;
        height: 54px;
        font-size: 30px;
        line-height: 54px;
        color: $color-white;
        background-color: $color-primary;
        border-radius: 10px;
        box-shadow: $card-shadow;
        margin: 0 0 8px;

        &.icon-revision {
            font-size: 34px;
        }

        &.icon-windscreen {
            span {
                &:before {
                    color: $color-white;
                }
            }
        }

        &.icon-instruments {
            font-size: 27px;
        }

        @include tablet-large {
            display: inline-block;
            vertical-align: top;
            width: 35px;
            height: 35px;
            font-size: 20px;
            line-height: 35px;
            margin: 0 12px 0 0;
            box-shadow: none;

            &.icon-revision {
                font-size: 23px;
            }

            &.icon-windscreen {
                font-size: 24px;
            }

            &.icon-instruments {
                font-size: 22px;
            }
        }

        @include desktop {
            margin: 0 22px 0 0;
        }
    }

    .quick-access-link {
        display: block;
        width: 100%;
        padding: 0;
        background: none;
        text-decoration: none;
        color: $color-black;

        @include tablet-large {
            padding: 20px 18px;
        }
    }

    .quick-access-title {
        font-weight: 700;
        font-size: 7px;
        line-height: 1;
        color: $color-dark;

        @include tablet-large {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 47px);
            font-size: 13px;
            line-height: 15px;
            text-align: left;
            margin: 8px 0 0;
        }

        @include desktop {
            width: calc(100% - 57px);
            font-size: 18px;
            line-height: 21px;
        }
    }
}
